.main-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}
.dark .footer {
    background: linear-gradient(180deg, rgba(17, 24, 39, 1) 0%, rgb(32, 45, 80) 100%);
}

.lastImg {
    height: auto;
    width: 80vw;
}
@media (min-width: 1024px) {
    .lastImg {
        height: 75vh;
        width: auto;
    }
}
@media (min-width: 1200px) {
    .lastImg {
        height: 80vh;
        width: auto;
    }
}
.App {
    transition: background-color 0.3s ease-out;
}
