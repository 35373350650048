.find {
    @apply transition-all lg:w-5/12 w-2/3 flex items-center;
}

.find-label {
    @apply transition-all z-10 flex justify-center items-center focus-within:text-blue-50 bg-white text-slate-900 dark:text-gray-400 dark:bg-gray-700 h-14 rounded-xl pl-3 p-2;
}

.dark .find-label {
    box-shadow: 4px 4px 4px rgba(70, 70, 70, 0.1), -4px -4px 4px rgba(10, 10, 10, 0.1),
        inset 12px 12px 12px rgba(70, 70, 70, 0.1), inset -12px -12px 12px rgba(10, 10, 10, 0.1);
}

.find-label:focus-within .dark {
    box-shadow: -4px -4px 8px rgba(70, 70, 70, 0.1), 4px 4px 8px rgba(10, 10, 10, 0.1),
        inset -12px -12px 24px rgba(70, 70, 70, 0.1), inset 12px 12px 24px rgba(10, 10, 10, 0.1);
    border: 2px solid rgba(239, 246, 255, 0.5);
}
.dark .icon-light {
    transform: rotate(180deg);
    transition: all 0.5s ease-in-out;
}
.icon-light {
    transform: rotate(0deg);
    transition: all 0.5s ease-in-out;
}
/* .div-light:hover .icon-light {
    transform: rotate(90deg);
    transition: all 0.3s ease-in-out;
} */
